(function(ns) {
	ns.AbstractController = function() {
		this.hasChanged = false;
		this.trumbowygOptions = {
			fullscreenable: false,
			btns: [
				"bold",
				"italic",
				"formatting",
				"underline",
				"unorderedList",
				"link"
			],
			lang: "fr",
			closable: false,
			removeformatPasted: true,
			semantic: true
		};
		this.canAddSlide = true;
	};

	ns.AbstractController.prototype.w = 0;
	ns.AbstractController.prototype.h = 0;

	ns.AbstractController.prototype.init = function() {
		main.controller.prototype.addListeners();

		main.controller.prototype.addWysiwyg();

		$(".form-slide-btadd").each(function() {
			var restrict = parseInt($(this).attr("data-restrict"));
			var index = $(this)
				.parent()
				.find(".form-slide-content").length;
			if (index + 1 >= restrict) {
				$(this).hide();
			}
		});
	};

	ns.AbstractController.prototype.loop = function() {};

	ns.AbstractController.prototype.addWysiwyg = function() {
		$(".form-area-wysiwyg").each(function() {
			$(this)
				.trumbowyg(main.controller.trumbowygOptions)
				.on("tbwblur", function(e) {
					main.controller.onTbBlur(e);
				});
		});
	};

	ns.AbstractController.prototype.onTbBlur = function(e) {};

	ns.AbstractController.prototype.removeWysiwyg = function() {
		$(".form-area-wysiwyg").each(function() {
			$(this).trumbowyg("destroy");
		});
	};

	ns.AbstractController.prototype.addListeners = function() {
		$(".bt-swap").on(Events.click, main.controller.prototype.onClickSwap);
		$(".bt-delete").on(
			Events.click,
			main.controller.prototype.onClickDelete
		);
		$(".bt-swap").draggable(main.controller.prototype.onSwapList);
		$(".draggable:not(.bt-swap)").draggable(
			main.controller.prototype.onSwap
		);
		$("#form-select-pagination").on(
			Events.change,
			main.controller.prototype.onChangePagination
		);
		$(".action-save").on(
			Events.click,
			main.controller.prototype.onClickSave
		);
		$(".action-back").on(
			Events.click,
			main.controller.prototype.onClickBack
		);
		$(
			".form-select:not(.distribution-select):not(.musicien-select),.form-input,.form-area"
		).on(Events.change, main.controller.prototype.onChangeForm);
		$(".profil-tab-item").on(
			Events.click,
			main.controller.prototype.onClickTab
		);
		$(".form-img").on(Events.error, main.controller.prototype.onImgError);
		$(".form-slide-btadd").on(
			Events.click,
			main.controller.prototype.onClickSlideAdd
		);
		$(".form-img-ui-remove").on(
			Events.click,
			main.controller.prototype.onClickSlideRemove
		);
		$(".form-img-ui-next").on(
			Events.click,
			main.controller.prototype.onClickSlideSwapNext
		);
		$(".form-img-ui-prev").on(
			Events.click,
			main.controller.prototype.onClickSlideSwapPrev
		);
		$(".form-video-button").on(
			Events.click,
			main.controller.prototype.onClickVideoImport
		);
		$(".form-select-hour,.form-select-minute").on(
			Events.change,
			main.controller.prototype.onChangeHour
		);
		$(".sidebar-item").on(
			Events.click,
			main.controller.prototype.onClickLeaveEdit
		);

		//main.controller.prototype.addWysiwyg();
	};

	ns.AbstractController.prototype.removeListeners = function() {
		$(".bt-swap").off(Events.click, main.controller.prototype.onClickSwap);
		$(".bt-delete").off(
			Events.click,
			main.controller.prototype.onClickDelete
		);
		$("#form-select-pagination").off(
			Events.change,
			main.controller.prototype.onChangePagination
		);
		$(".action-save").off(
			Events.click,
			main.controller.prototype.onClickSave
		);
		$(".action-back").off(
			Events.click,
			main.controller.prototype.onClickBack
		);
		$(
			".form-select:not(.distribution-select):not(.musicien-select),.form-input,.form-area"
		).off(Events.change, main.controller.prototype.onChangeForm);
		$(".profil-tab-item").off(
			Events.click,
			main.controller.prototype.onClickTab
		);
		$(".form-img").off(Events.error, main.controller.prototype.onImgError);
		$(".form-slide-btadd").off(
			Events.click,
			main.controller.prototype.onClickSlideAdd
		);
		$(".form-img-ui-remove").off(
			Events.click,
			main.controller.prototype.onClickSlideRemove
		);
		$(".form-img-ui-next").off(
			Events.click,
			main.controller.prototype.onClickSlideSwapNext
		);
		$(".form-img-ui-prev").off(
			Events.click,
			main.controller.prototype.onClickSlideSwapPrev
		);
		$(".form-video-button").off(
			Events.click,
			main.controller.prototype.onClickVideoImport
		);
		$(".form-select-hour,.form-select-minute").off(
			Events.change,
			main.controller.prototype.onChangeHour
		);
		$(".sidebar-item").off(
			Events.click,
			main.controller.prototype.onClickLeaveEdit
		);

		//main.controller.prototype.removeWysiwyg();
	};

	ns.AbstractController.prototype.onClickLeaveEdit = function(e) {
		if (main.controller.prototype.hasChanged) {
			if (!confirm("Vous n'avez pas sauvegardé, voulez-vous quitter?")) {
				e.preventDefault();
				return false;
			}
		}
	};

	ns.AbstractController.prototype.onChangeHour = function(e) {
		var $parent = $(this).parent();
		var hour = $parent.find(".form-select-hour option:selected").val();
		var minute = $parent.find(".form-select-minute option:selected").val();

		$parent.find("input").val(hour + ":" + minute);
	};

	ns.AbstractController.prototype.onClickVideoImport = function(e) {
		if (e.preventDefault) e.preventDefault();

		var $parent = $(this).parent();
		var $bt = $parent.find(".button");
		var $loading = $parent.find(".form-loading");
		var urlSave = $parent.attr("data-urlsave");
		var val = $parent.find("input").val();
		var $youtube = $parent.find(".form-youtube");
		var $vimeo = $parent.find(".form-vimeo");
		var urlImg = "";

		$bt.css("display", "none");
		$loading.css("display", "block");

		var isYoutube =
			val.indexOf("youtube") != -1 || val.indexOf("youtu.be") != -1;
		var isVimeo = val.indexOf("vimeo") != -1;
		if (!isYoutube && !isVimeo) {
			$bt.css("display", "block");
			$loading.css("display", "none");
			alert("Le lien ne semble pas être de youtube ou de viméo.");
			return;
		}

		var id = isYoutube ? getYoutubeId(val) : getVimeoId(val);

		if (isVimeo) {
			$parent.find(".form-vimeo").attr("src", getVimeoSrc(val));
			var url = getVimeoApiUrl(val);
			main.callGet(url, function(rs) {
				var resultat = JSON.parse(rs);
				if (resultat[0]) {
					resultat = resultat[0];
					urlImg = resultat.thumbnail_large;
					urlImg = urlImg.replace("640.webp", "1280.jpg");
					urlImg = urlImg.replace("640.jpg", "1280.jpg");
					urlImg = urlImg.replace("640.jpeg", "1280.jpeg");

					main.controller.prototype.saveImgImportedFromVideo(
						urlImg,
						urlSave,
						$bt,
						$loading
					);
				}
			});
		} else if (isYoutube) {
			$youtube.attr("src", getYoutubeSrc(id));

			$bt.css("display", "block");
			$loading.css("display", "none");

			urlImg = getYoutubeImgUrl(id);
			main.controller.prototype.saveImgImportedFromVideo(
				urlImg,
				urlSave,
				$bt,
				$loading
			);
		}

		return false;
	};

	ns.AbstractController.prototype.saveImgImportedFromVideo = function(
		urlImg,
		urlSave,
		$bt,
		$loading
	) {
		if (!urlSave) {
			$bt.css("display", "block");
			$loading.css("display", "none");
			return false;
		}
		var urlParam = btoa(urlImg);
		urlParam = urlParam.replace(new RegExp("=", "g"), "signeegal");
		urlParam = urlParam.replace(new RegExp("/", "g"), "signeslash");
		urlSave += "/" + urlParam + "/article";
		main.callGet(urlSave, function(name) {
			if (!name) {
				console.log("img url was not saved : " + urlImg);
				return;
			}

			var $target = $("#form-profil").find(
				".form-img-container-video_cover .form-img-content"
			);
			//console.log($target);
			$bt.css("display", "block");
			$loading.css("display", "none");
			if (!$target[0]) return;
			$target.load(function() {
				$target.off();
			});
			$target.html("");
			var restrict = $target.attr("data-restrict")
				? parseInt($target.attr("data-restrict"))
				: null;
			var size =
				"/" +
				$target.attr("data-realw") +
				"/" +
				$target.attr("data-realh");
			var value = name;
			var url = $target.attr("data-url") + "/" + value + size;
			var img = document.createElement("img");
			img.src = url;
			img.className = "form-img form-img-imgs";
			img.style.zIndex = 0;
			img.style.width = $target.attr("data-width") + "px";
			$target.append(img);
			$target
				.parent()
				.find(".form-img-input")
				.attr("value", value);

			main.controller.prototype.addDroppable();
		});
	};

	ns.AbstractController.prototype.onClickSlideSwapNext = function(e) {
		var target = $(this)
			.parent()
			.parent()[0];
		var previous = target.previousElementSibling;
		$(previous).before(target);

		main.controller.prototype.addDroppable();
		main.controller.prototype.removeListeners();
		main.controller.prototype.addListeners();
	};

	ns.AbstractController.prototype.onClickSlideSwapPrev = function(e) {
		var target = $(this)
			.parent()
			.parent()[0];
		var next = target.nextElementSibling;
		$(next).after(target);
		$(".ajaxupload").remove();
		main.controller.prototype.addDroppable();
		main.controller.prototype.removeListeners();
		main.controller.prototype.addListeners();
	};

	ns.AbstractController.prototype.onClickSlideRemove = function(e) {
		$(".ajaxupload").remove();
		main.controller.prototype.removeListeners();

		$(this)
			.parent()
			.parent()
			.parent()
			.parent()
			.find(".form-slide-btadd ")
			.show();
		$(this)
			.parent()
			.parent()
			.remove();

		main.controller.prototype.addDroppable();
		main.controller.prototype.addListeners();
	};

	ns.AbstractController.prototype.onClickSlideAdd = function(e) {
		if (e.preventDefault()) e.preventDefault();

		if (!main.controller.canAddSlide) return;

		main.controller.canAddSlide = false;
		$(".ajaxupload").remove();
		main.controller.prototype.removeListeners();

		var $bt = $(this);
		var $slider = $bt.parent();
		var dbcolumn = $bt.attr("data-dbcolumn");
		var w = $bt.attr("data-width");
		var h = $bt.attr("data-height");
		var realw = $bt.attr("data-realw");
		var realh = $bt.attr("data-realh");
		var restrict = parseInt($bt.attr("data-restrict"));
		var index = $slider.find(".form-slide-content").length;
		var url =
			$bt.attr("href") +
			"/" +
			dbcolumn +
			"/" +
			w +
			"/" +
			h +
			"/" +
			realw +
			"/" +
			realh +
			"/" +
			index;

		if (index + 1 >= restrict) {
			$bt.hide();
		}

		main.callGet(url, function(rs) {
			$slider.find(".form-slide-contents").append(rs);
			main.controller.prototype.addDroppable();
			main.controller.prototype.addListeners();

			main.controller.canAddSlide = true;
		});

		return false;
	};

	ns.AbstractController.prototype.onSwapList = function($to, $from, sens) {
		if (sens === -1) {
			$from.insertAfter($to);
		} else {
			$from.insertBefore($to);
		}
		$(".list-line-item-order").each(function(index) {
			this.innerHTML = index + 1;
		});

		$(".list-lines").each(function() {
			$(this)
				.find(".list-line")
				.each(function(index) {
					var order = this.getAttribute("data-order");
					//order = parseInt(order) - 1;
					this.setAttribute("data-order", index);
					main.controller.prototype.orderListItem($(this));
				});
		});

		main.controller.prototype.addDroppable();
		main.controller.prototype.removeListeners();
		main.controller.prototype.addListeners();

		if (main.controller.afterSwap) main.controller.afterSwap($to, $from);
	};

	ns.AbstractController.prototype.onSwap = function($to, $from, sens) {
		var toClass = $to[0].className;
		var fromClass = $from[0].className;
		$to.attr("class", fromClass);
		$from.attr("class", toClass);

		if (sens === -1) {
			$from.insertAfter($to);
		} else {
			$from.insertBefore($to);
		}

		if (main.controller.afterSwap) {
			main.controller.afterSwap($to, $from);
		} else {
			main.controller.prototype.removeListeners();
			main.controller.prototype.addListeners();
		}

		main.controller.prototype.addWysiwyg();
	};

	ns.AbstractController.prototype.orderListItem = function(
		$listitem,
		callback
	) {
		var id = $listitem.attr("data-id");
		var order = $listitem.attr("data-order");
		var url = $listitem.find(".bt-swap").attr("href");
		var _token = $listitem.find(".bt-swap").attr("data-token");
		main.callPost(
			url,
			"_token=" + _token + "&id=" + id + "&order=" + order,
			callback
		);
	};

	ns.AbstractController.prototype.onImgError = function(e) {
		this.src = this.src;
		/*
		 var src_default = $(this).parent().attr("data-default");
		 if (src_default !== undefined) {
		 this.src = src_default;
		 }
		 */
	};

	ns.AbstractController.prototype.onChangeForm = function(e) {
		main.controller.prototype.setHasChanged(true);

		var val = "";
		if ($(this).is("select")) {
			val = $(this)
				.find("option:selected")
				.val();
			$(this)
				.parent()
				.find("input")
				.val(val);
		}
	};

	ns.AbstractController.prototype.onClickTab = function(e) {
		//profil-tab-content-
		var index = $(this).attr("data-index");
		$(".profil-tab-item").removeClass("profil-tab-item-selected");
		$(this).addClass("profil-tab-item-selected");
		$(".profil-tab-content").removeClass("profil-tab-content-selected");
		$("#profil-tab-content-" + index).addClass(
			"profil-tab-content-selected"
		);

		$(".ajaxupload").remove();
		main.controller.prototype.addDroppable();

		if (main.controller.onChangeTab) {
			main.controller.onChangeTab(index, true);
			main.controller.prototype.checkForm();
		}
	};

	ns.AbstractController.prototype.onClickBack = function(e) {
		if ($(this).hasClass("actionbar-item-disabled")) return false;
		if (main.controller.prototype.hasChanged) {
			if (!confirm("Vous n'avez pas sauvegardé, voulez-vous quitter?")) {
				e.preventDefault();
				return false;
			}
		}
	};

	ns.AbstractController.prototype.onClickSave = function(e) {
		e.preventDefault();
		if ($(this).hasClass("actionbar-item-disabled")) return false;
		main.controller.prototype.saveForm();
		return false;
	};

	ns.AbstractController.prototype.onClickSwap = function(e) {
		e.preventDefault();
		return false;
	};

	ns.AbstractController.prototype.onClickDelete = function(e) {
		if (
			!confirm(
				"Cette action supprimera l'élément. Ok pour valider l'action."
			)
		) {
			e.preventDefault();
			return false;
		}
		if (main.controller.onDelete) main.controller.onDelete(this);
	};

	ns.AbstractController.prototype.onChangePagination = function(e) {
		var val = $("#form-select-pagination")
			.find("option:selected")
			.val();
		var url = $(".pagination").attr("data-url");
		url = url.replace("{page}", val);
		window.location.href = url;
	};

	ns.AbstractController.prototype.checkForm = function() {
		var check = false;
		var num_error = 0;

		$(".validator-notempty").each(function() {
			if (!validateNotEmpty($(this))) num_error++;
		});

		$(".validator-email").each(function() {
			if (!validateEmail($(this))) num_error++;
		});

		$(".validator-password").each(function() {
			if (!validatePassword($(this))) num_error++;
		});

		$(".validator-link").each(function() {
			if (!validateLink($(this))) num_error++;
		});

		$(".validator-oneorother").each(function() {
			var other = document.getElementById(
				this.getAttribute("data-other")
			);
			var tocheck = this.value && this.value != "" ? this : other;

			if (!validateNotEmpty($(tocheck))) num_error++;
		});

		$(".validator-selectneeded").each(function() {
			var check = this.options[this.selectedIndex].value != -1;

			var $parent = $(this).parent();
			$parent.addClass("warning");
			if (check) {
				$parent.removeClass("warning");
			} else {
				num_error++;
			}
		});

		if (main.controller.customCheck) {
			num_error += main.controller.customCheck();
		}

		check = num_error === 0;

		$(".actionbar-state-error-after").html();
		$(".actionbar").removeClass("error");
		if (!check) {
			$(".actionbar-state-error-after").html(num_error);
			$(".actionbar").addClass("error");
		}

		$(".profil-tab-content").each(function() {
			var lg = $(this).find(".warning").length;

			$(this)
				.find(".blocks-item-txt.warning")
				.each(function() {
					if (
						$(this)
							.find("input")
							.val() === "" &&
						$(this)
							.find("textarea")
							.val() === ""
					) {
						lg++;
					}
				});

			var $tab = $("#profil-tab-item-" + this.getAttribute("data-index"));
			$tab.removeClass("warning");
			$tab.find(".profil-tab-item-error")[0].style.opacity = 0;
			$tab.find(".profil-tab-item-error").html("");
			if (lg > 0) {
				$tab.addClass("warning");
				$tab.find(".profil-tab-item-error").html(lg);
				$tab.find(".profil-tab-item-error")[0].style.opacity = 1;
				check = false;
			}
		});

		return check;
	};

	ns.AbstractController.prototype.saveForm = function() {
		if (main.controller.prototype.checkForm()) {
			$(".actionbar").removeClass("error");

			var toadd = "";
			var form = document.getElementById("form-profil");
			form = form ? form : document.getElementById("form-profil-0");

			var url = form.getAttribute("action");
			var url_base = form.getAttribute("data-url_base");
			if (main.controller.onSaveForm) {
				var onsaveform = main.controller.onSaveForm();
				if (!onsaveform.check) return;
				toadd = onsaveform.add;
				if (onsaveform.url) {
					url = onsaveform.url;
					url_base = url;
				}
			}

			var serialize =
				$(
					"input:not(.block-input),textarea:not(.block-input)"
				).serialize() + toadd;
			//console.log(serialize);
			//console.log($("#form-profil").serializeArray());
			main.showLoader("");
			$(".actionbar").removeClass("saved");
			$(".actionbar").removeClass("changed");
			$(".actionbar").addClass("saving");
			//console.log(url);
			main.callPost(url, serialize, function(rs) {
				main.controller.prototype.hasChanged = false;
				$(".actionbar").removeClass("saving");
				$(".actionbar").addClass("saved");

				rs = JSON.parse(rs);
				if (window.location.href.indexOf("new") != -1) {
					//window.location.href = window.location.href.replace("new", "get/fr") + "/" + rs.id;
					window.location.href = window.location.href.replace(
						"/new",
						""
					);
				} else {
					main.hideLoader();
				}
			});
		}
	};

	ns.AbstractController.prototype.addDroppable = function() {
		$(".ajaxupload").remove();
		$(".droppable").each(function() {
			//console.log(this);
			var callback = main.controller.prototype.onDrop;
			var trigger = this.parentNode.querySelector(".trigger");
			var target = trigger ? trigger : this.parentNode;
			window.addUpload(this, callback, target, "form-upload-img");
		});

		$(".droppable-video").each(function() {
			//console.log(this);
			var callback = main.controller.prototype.onDropVideo;
			var trigger = this.parentNode.querySelector(".trigger");
			var target = trigger ? trigger : this.parentNode;
			//console.log(target.nodeType);
			window.addUpload(this, callback, target, "form-upload-video");
		});

		$(".droppable-document").each(function() {
			var callback = main.controller.prototype.onDropDocument;
			var trigger = this.parentNode.querySelector(".trigger");
			var target = trigger ? trigger : this.parentNode;
			//console.log(target.nodeType);
			window.addUpload(this, callback, target, "form-upload-document");
		});

		$(".droppable-sound").each(function() {
			var callback = main.controller.prototype.onDropSoundPlaylist;
			var trigger = this.parentNode.querySelector(".trigger");
			var target = trigger ? trigger : this;
			//console.log(target.nodeType);
			window.addUpload(this, callback, target, "form-upload-sound");
		});

		$(".droppable-sound-solo").each(function() {
			var callback = main.controller.prototype.onDropSound;
			var trigger = this.parentNode.querySelector(".trigger");
			var target = trigger ? trigger : this;
			//console.log(target.nodeType);
			window.addUpload(this, callback, target, "form-upload-sound");
		});
	};

	ns.AbstractController.prototype.onDropVideo = function(target, response) {
		var $target = $(target);
		var rs = JSON.parse(response);
		//console.log(rs);
		//console.log(target);
		if (rs.status) {
			$target.load(function() {
				$target.off();
			});
			var $items = $target.find(".form-video-items");
			$items.html("");
			var filename = "";
			var value = [];
			var i = 0;
			var lg = rs.filename.length;
			var div = null;
			for (i; i < lg; i++) {
				filename = rs.filename[i];
				value.push(filename);
				div = document.createElement("div");
				div.className = "form-video-item fa fa-check";
				div.innerHTML = filename;
				$items.append(div);
			}
			value = value.join(",");
			$target
				.parent()
				.find(".form-video-input")
				.attr("value", value);

			main.controller.prototype.addDroppable();
		} else {
			alert(rs.errors);
		}
	};

	ns.AbstractController.prototype.onDropDocument = function(
		target,
		response
	) {
		var $target = $(target);
		var rs = JSON.parse(response);
		//console.log(rs);
		//console.log(target);
		if (rs.status) {
			$target.load(function() {
				$target.off();
			});
			var $items = $target.find(".form-document-items");
			$items.html("");
			var filename = "";
			var value = [];
			var i = 0;
			var lg = rs.filename.length;
			var div = null;
			for (i; i < lg; i++) {
				filename = rs.filename[i];
				value.push(filename);
				div = document.createElement("div");
				div.className = "form-document-item fa fa-check";
				div.innerHTML = filename;
				$items.append(div);
			}
			value = value.join(",");
			$target
				.parent()
				.find(".form-document-input")
				.attr("value", value);

			main.controller.prototype.addDroppable();
		} else {
			alert(rs.errors);
		}
	};

	ns.AbstractController.prototype.onDropSoundPlaylist = function(
		target,
		response
	) {
		var $target = $(target);
		var rs = JSON.parse(response);

		var container = document.querySelector($target.attr("data-target"));
		var url = $target.attr("data-tpl");
		var dbcolumn = "playlist";
		var index = $(".playlist-item").length;
		var value = rs.filename[0];

		url += "/" + index + "/" + dbcolumn + "/" + value;
		//console.log(rs);
		//console.log(target);
		if (rs.status) {
			$target.load(function() {
				$target.off();
			});
			main.callGet(url, function(rs) {
				var div = document.createElement("div");
				div.innerHTML = rs;
				var firstChild = div.firstChild;
				container.append(firstChild);

				var $inputChanged = $(container).find(".playlist-item-label");

				main.controller.prototype.addDroppable();
				main.controller.addListener();

				$inputChanged.trigger("change");
			});
		} else {
			alert(rs.errors);
		}
	};

	ns.AbstractController.prototype.onDropSound = function(target, response) {
		var $target = $(target);
		var rs = JSON.parse(response);

		//console.log(rs);
		//console.log(target);
		if (rs.status) {
			$target.load(function() {
				$target.off();
			});

			var value = rs.filename[0];
			//playlist-item-audio

			var $parent = $target.parent();
			if ($target.attr("data-target")) {
				$parent = $($target.attr("data-target"));
			}
			//console.log($parent);
			var $audio = $parent.find(".playlist-item-audio");
			var url = $audio.attr("data-src") + "/" + value;

			$audio.attr("src", url);

			var name = value.split("_")[2];
			name = name.replace(".mp3", "");
			//console.log($parent.find("input[name=title]"));
			$parent.find("input[name=title]").val(name);
			$parent.find(".playlist-item-label").val(name);
			$parent.find(".playlist-item-label").attr("data-value", name);
			$parent.find(".playlist-item-label").trigger("change");
			$parent.find("input[name=media]").val(value);
			$parent.find("input[name=value]").val(value);

			$parent.find(".playlist-item-content").removeClass("hidden");

			main.controller.prototype.addDroppable();
			main.controller.addListener();

			main.controller.blocksHasChanged = true;
		} else {
			alert(rs.errors);
		}
	};

	ns.AbstractController.prototype.onDrop = function(target, response) {
		var $target = $(target);
		console.info(response);
		var rs = JSON.parse(response);
		if (rs.status) {
			$target.load(function() {
				$target.off();
			});
			$target.html("");
			var restrict = $target.attr("data-restrict")
				? parseInt($target.attr("data-restrict"))
				: null;
			var size =
				"/" +
				$target.attr("data-realw") +
				"/" +
				$target.attr("data-realh");
			var url = $target.attr("data-url");
			var filename = "";
			var value = [];
			var i = 0;
			var lg = rs.filename.length;
			if (restrict) lg = lg > restrict ? restrict : lg;
			var img = null;
			for (i; i < lg; i++) {
				filename = rs.filename[i];
				value.push(filename);
				img = document.createElement("img");
				img.src = url + "/" + filename + size;
				img.className = "form-img form-img-imgs";
				img.style.zIndex = i;
				img.style.width = $target.attr("data-width") + "px";
				//img.style.height = $target.attr("data-height") + "px";
				$target.append(img);
			}

			value = value.join(",");
			$target
				.parent()
				.find(".form-img-input")
				.attr("value", value);

			main.controller.prototype.addDroppable();

			main.controller.prototype.setHasChanged(true);
		} else {
			alert(rs.errors);
		}
		if (main.controller.onDrop) main.controller.onDrop(target, response);
	};

	ns.AbstractController.prototype.setHasChanged = function(bool) {
		main.controller.prototype.hasChanged = bool;
		var savebt = document.querySelector(".action-save");
		if (savebt) savebt.disabled = bool ? "" : "disabled";

		if (bool) {
			$(".actionbar").removeClass("saved");
			$(".actionbar").addClass("changed");
		}
	};

	ns.AbstractController.prototype.resize = function(w, h) {
		this.w = w;
		this.h = h;
	};

	ns.AbstractController.prototype.dispose = function() {
		for (var s in this) {
			this[s] = null;
		}
		main.controller.prototype.removeListeners();
	};
})(AKARUCMS);
