(function(ns) {
	ns.Block = function(el) {
		this.el = el;
		this.btdelete = this.el.querySelector(".block-ui-bt.delete");
		this.btup = this.el.querySelector(".block-ui-bt.up");
		this.btdown = this.el.querySelector(".block-ui-bt.down");

		this.canMove = true;

		this.addListeners();
	};

	ns.Block.prototype.addListeners = function() {
		this.btdelete.addEventListener("click", this);
		this.btup.addEventListener("click", this);
		this.btdown.addEventListener("click", this);
	};

	ns.Block.prototype.removeListeners = function() {
		this.btdelete.removeEventListener("click", this);
		this.btup.removeEventListener("click", this);
		this.btdown.removeEventListener("click", this);
	};

	ns.Block.prototype.handleEvent = function(event) {
		switch (event.target) {
			case this.btdelete:
				if (
					confirm(
						"Cette action supprimera l'élément. Ok pour valider l'action."
					)
				) {
					this.removeListeners();
					this.el.parentNode.removeChild(this.el);
				}
				break;
			case this.btup:
				this.up();
				break;
			case this.btdown:
				this.down();
				break;
		}
	};

	ns.Block.prototype.up = function() {
		if (this.canMove && this.el.previousElementSibling) {
			this.canMove = false;
			this.el.classList.add("hide");
			var self = this;
			setTimeout(function() {
				self.el.parentNode.insertBefore(
					self.el,
					self.el.previousElementSibling
				);
				self.el.classList.remove("hide");
				self.canMove = true;
				TweenLite.to(window, 0.4, {
					scrollTo: { y: self.el, offsetY: 300 }
				});
				TweenLite.fromTo(
					self.el,
					0.4,
					{ y: 100, opacity: 0 },
					{
						y: 0,
						opacity: 1,
						ease: Quart.easeOut,
						delay: 0.4
					}
				);
			}, 400);
		}
	};

	ns.Block.prototype.down = function() {
		if (this.canMove && this.el.nextElementSibling) {
			this.canMove = false;
			this.el.classList.add("hide");
			var self = this;
			setTimeout(function() {
				/*self.el.parentNode.insertBefore(
					self.el,
					self.el.nextElementSibling
				);*/
				self.el.nextElementSibling.after(self.el);
				self.el.classList.remove("hide");
				self.canMove = true;
				TweenLite.to(window, 0.4, {
					scrollTo: { y: self.el, offsetY: 300 }
				});
				TweenLite.fromTo(
					self.el,
					0.4,
					{ y: -100, opacity: 0 },
					{
						y: 0,
						opacity: 1,
						ease: Quart.easeOut,
						delay: 0.4
					}
				);
			}, 400);
		}
	};

	ns.Block.prototype.destroy = function() {
		this.removeListeners();
	};
})(AKARUCMS);
