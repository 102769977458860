(function(ns) {
	ns.BlockItem = function(el) {
		this.el = el;
		this.select = this.el.querySelector("select[name=type]");
		this.options = this.select.querySelectorAll("option");

		this.addListeners();
	};

	ns.BlockItem.prototype.addListeners = function() {
		this.select.addEventListener("change", this);
	};

	ns.BlockItem.prototype.removeListeners = function() {
		this.select.removeEventListener("change", this);
	};

	ns.BlockItem.prototype.handleEvent = function(event) {
		switch (event.type) {
			case "change":
				for (var i = 0; i < this.options.length; i++) {
					var option = this.options[i];
					this.el.classList.remove("block-item-" + option.value);
				}
				this.el.classList.add("block-item-" + event.target.value);
				break;
		}
	};

	ns.BlockItem.prototype.destroy = function() {
		this.removeListeners();
	};
})(AKARUCMS);
