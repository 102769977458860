(function($, window, document) {
	$.fn.draggable = function(ondrop) {
		return this.each(function() {
			var self = this;
			window.mouseYStart = 0;
			window.stock = window.stock || {};
			this.addDrag = function() {
				$(this).attr("draggable", "true");
				this.ondragstart = function(e) {
					if (this.getAttribute("data-target")) {
						e.dataTransfer.setData(
							"Text",
							this.getAttribute("data-target")
						);
					} else {
						e.dataTransfer.setData("Text", "#" + e.target.id);
					}
					var classe = self.className.split(" ")[0];
					$("." + classe).addClass("ondragstart");
					$(this).addClass("ondragstart-target");

					window.mouseYStart = $(this)
						.parent()
						.position().top;

					//console.log(e.dataTransfer.getData("Text"));
					//window.mouseYStart = window.mouseYStart == 0 ? $(this).parent().parent().position().top : window.mouseYStart;
				};

				this.ondragout = function(e) {
					$(e.target).removeClass("ondragover");
					$(e.target).removeClass("down");
				};
				this.ondragover = function(e) {
					var $t = $(e.target);
					if (!$t.hasClass("draggable")) return false;

					$t.addClass("ondragover");

					var top = $t.parent().position().top;
					//top = top == 0 ? $t.parent().parent().position().top:top;
					if (window.mouseYStart < top) {
						$t.addClass("down");
					}
					//console.log(window.mouseYStart," / ",$t.parent().position().top);

					return false;
				};
				this.ondragenter = function(e) {
					var $t = $(e.target);
					if (!$t.hasClass("draggable")) return false;
					$t.addClass("ondragover");
					return false;
				};
				this.ondragend = function(e) {
					self.unClass();
				};
				this.ondragleave = function(e) {
					var $t = $(e.target);
					if (!$t.hasClass("draggable")) return false;
					$t.removeClass("ondragover");
					$t.removeClass("down");
				};
				this.ondrop = function(e) {
					var sens = main.mousey > e.clientY ? -1 : 1;
					var data = e.dataTransfer.getData("Text");
					var $from = $(data);
					var $to = $(e.target);

					main.controller.prototype.removeWysiwyg();

					$(e.target).removeClass("ondragstart");
					if (e.target.getAttribute("data-target")) {
						$to = $(e.target.getAttribute("data-target"));
					}
					if ($from.parent().attr("id") != $to.parent().attr("id")) {
						self.unClass();
						return false;
					}
					//console.log($to);
					var toHtml = $to.html();
					var fromHtml = $from.html();
					var toId = $to[0].id;
					var fromId = $from[0].id;

					$to.html(fromHtml);
					$from.html(toHtml);

					var toDataset = $to[0].dataset;
					var dataset = "";
					for (var s in toDataset) {
						if (s === "order") continue;
						dataset = $from[0].dataset[s];
						$from[0].dataset[s] = toDataset[s];
						$to[0].dataset[s] = dataset;
					}
					$from[0].id = toId;
					$to[0].id = fromId;

					self.unClass();

					if (ondrop) ondrop($to, $from, sens);
					return false;
				};
			};

			this.classe = "";

			this.unClass = function() {
				$(".ondragstart").removeClass("ondragstart");
				$(".ondragstart-target").removeClass("ondragstart-target");
				$(".ondragover").removeClass("ondragover");
				$(".down").removeClass("down");
			};

			this.removeDrag = function() {
				$(this).attr("draggable", "false");
				this.ondragstart = this.ondragout = this.ondragover = this.ondragenter = this.ondragend = this.ondragleave = this.ondrop = null;
			};

			this.addDrag();
			return this;
		});
		//return this;
	};
})(jQuery, window, window.document);
