/* ===========================================================
 * fr.js
 * French translation for Trumbowyg
 * http://alex-d.github.com/Trumbowyg
 * ===========================================================
 * Author : Alexandre Demode (Alex-D)
 *          Twitter : @AlexandreDemode
 *          Website : alex-d.fr
 */
jQuery.trumbowyg.langs.fr = {
	viewHTML: "Voir le HTML",
	formatting: "Format",
	p: "Paragraphe",
	blockquote: "Citation",
	code: "Code",
	header: "Titre",
	bold: "Gras",
	italic: "Italique",
	strikethrough: "Rayé",
	underline: "Souligné",
	strong: "Fort",
	em: "Emphase",
	del: "Supprimé",
	unorderedList: "Liste à puces",
	orderedList: "Liste ordonnée",
	insertImage: "Insérer une image",
	insertVideo: "Insérer une video",
	link: "Lien",
	createLink: "Insérer un lien",
	unlink: "Supprimer le lien",
	justifyLeft: "Aligner à gauche",
	justifyCenter: "Centrer",
	justifyRight: "Aligner à droite",
	justifyFull: "Justifier",
	horizontalRule: "Insérer un séparateur horizontal",
	fullscreen: "Plein écran",
	close: "Fermer",
	submit: "Valider",
	reset: "Annuler",
	required: "Obligatoire",
	description: "Description",
	title: "Titre",
	text: "Texte"
};
