(function(ns) {
	ns.HomeController = function() {
		ns.AbstractController.call(this); //super constructor
	};
	var controller = ns.HomeController;
	controller.prototype = Object.create(ns.AbstractController); //extends
	controller.prototype.constructor = controller; //set constructor
	controller.prototype.resize = function(w, h) {
		this.prototype.resize.call(this, w, h);
	};

	controller.prototype.init = function() {
		$("#form-login").bind(Events.submit, this.onSubmit);
	};

	controller.prototype.onSubmit = function(e) {
		var check = true;

		check = validateEmail($("#email")) ? check : false;
		check = validateNotEmpty($("#password")) ? check : false;
		return check;
	};

	controller.prototype.dispose = function() {
		this.prototype.dispose.call(this);
	};
})(AKARUCMS);
