/* eslint-disable no-undef */
(function(ns) {
	ns.BaseController = function() {
		ns.AbstractController.call(this); //super constructor
		this.countSetImg = 0;
		this.status = 0;
		this.urlBlock = "";
		this.urlBlocks = "";
		this.urlSelector = "";
		this.urlSlider = "";
		this.urlCredit = "";
		this.indexTab = 0;
		this.blocksHasChanged = false;
	};

	var controller = ns.BaseController;
	controller.prototype = Object.create(ns.AbstractController); //extends
	controller.prototype.constructor = controller; //set constructor
	controller.prototype.resize = function(w, h) {
		this.prototype.resize.call(this, w, h);
	};

	controller.prototype.init = function() {
		this.prototype.init.call(this);

		this.addListener();

		$(".validator-mediatype").each(this.onChangeMedia);

		this.setImg();

		this.urlBlock = $(".blocks").attr("data-urlblock");
		this.urlBlocks = $(".blocks").attr("data-urlblocks");
		this.urlSlider = $(".blocks").attr("data-urlslider");
		this.urlSelector = $("#form-list").attr("data-urlselector");
		this.urlSave = $("#form-list").attr("data-urlsave");
		this.urlCredit = $("#credits").attr("data-url");

		this.onActionContenuChange();

		this.blocks = [];
		this.blockItems = [];
		this.setBlocks();

		this.setTimeoutId = null;
		this.checkDeployStatus();
	};

	controller.prototype.checkDeployStatus = function() {
		//console.info("checkDeployStatus");
		var self = this;
		if (self.setTimeoutId) {
			clearTimeout(self.setTimeoutId);
			self.setTimeoutId = null;
		}
		var el = document.querySelector(".sidebar-deploy-status");
		if (!el) return;

		main.callGet(
			"https://api.netlify.com/api/v1/sites/8ae2e760-034d-4fa6-b50b-e692bb530fe1?access_token=204eb1336b23bd4062a73b8f81a75ff31d2a7ac93eb5e330dccaa3f5152565c3",
			function(rs) {
				rs = JSON.parse(rs);
				if (rs && rs.state) {
					// console.info(rs.state);
					if (rs.state !== "current") rs.state = "building";
					el.classList.remove("current");
					el.classList.remove("building");
					el.classList.add(rs.state);
				}

				self.setTimeoutId = setTimeout(
					self.checkDeployStatus.bind(self),
					1 * 60 * 1000
				);
			}
		);
	};

	controller.prototype.callBuild = function(event) {
		event.preventDefault();
		var el = document.querySelector(".sidebar-deploy-status");
		if (!el) return;

		main.callGet(this.href, function(rs) {
			console.log(rs);

			main.controller.checkDeployStatus();
			window.location.reload();
		});
	};

	controller.prototype.setBlocks = function() {
		var i = 0;
		var element = null;
		for (i = 0; i < this.blockItems.length; i++) {
			var blockItem = this.blockItems[i];
			blockItem.destroy();
		}

		var blockItems = document.querySelectorAll(".block-item");
		this.blocks = [];
		for (i = 0; i < blockItems.length; i++) {
			element = blockItems[i];
			this.blockItems.push(new AKARUCMS.BlockItem(element));
		}

		for (i = 0; i < this.blocks.length; i++) {
			var block = this.blocks[i];
			block.destroy();
		}

		var blocks = document.querySelectorAll(".block");
		this.blocks = [];
		for (i = 0; i < blocks.length; i++) {
			element = blocks[i];
			this.blockItems.push(new AKARUCMS.Block(element));
		}
	};

	controller.prototype.addListener = function() {
		main.controller.prototype.addDroppable();

		$(".droppable-slider").each(function() {
			var callback = main.controller.onDropSlider;
			var target = this;
			window.addUpload(this, callback, target, "form-upload-img");
		});

		main.controller.prototype.removeListeners();
		main.controller.prototype.addListeners();

		$("#btdeploy").off(Events.click, main.controller.callBuild);
		$("#btdeploy").on(Events.click, main.controller.callBuild);

		$(".validator-mediatype").off(
			Events.change,
			main.controller.onChangeMedia
		);
		$(".validator-mediatype").on(
			Events.change,
			main.controller.onChangeMedia
		);

		$(".form-list-bt-delete").off(
			Events.click,
			main.controller.prototype.onClickDelete
		);
		$(".form-list-bt-delete").on(
			Events.click,
			main.controller.prototype.onClickDelete
		);

		$(".form-img-btdelete").off(
			Events.click,
			main.controller.onClickImgDelete
		);
		$(".form-img-btdelete").on(
			Events.click,
			main.controller.onClickImgDelete
		);

		$(".form-slider-item-delete").off(
			Events.click,
			main.controller.onClickSliderItemDelete
		);
		$(".form-slider-item-delete").on(
			Events.click,
			main.controller.onClickSliderItemDelete
		);

		$(".bt-activation.isactive").off(
			Events.click,
			main.controller.onClickSelectorBtremove
		);
		$(".bt-activation.isactive").on(
			Events.click,
			main.controller.onClickSelectorBtremove
		);

		$(".bt-activation:not(.isactive)").off(
			Events.click,
			main.controller.onClickSelectorBtadd
		);
		$(".bt-activation:not(.isactive)").on(
			Events.click,
			main.controller.onClickSelectorBtadd
		);

		$(".action-savelist").off(Events.click, main.controller.savelist);
		$(".action-savelist").on(Events.click, main.controller.savelist);

		$(".form-input-videolink").off(
			Events.change,
			main.controller.onBlockVideoChange
		);
		$(".form-input-videolink").on(
			Events.change,
			main.controller.onBlockVideoChange
		);

		$(".dates-item-data").off(Events.change, main.controller.onDatesChange);
		$(".dates-item-data").on(Events.change, main.controller.onDatesChange);

		//
		$(".block-item-video-button").off(
			Events.click,
			main.controller.onClickImportVideoFromUrl
		);
		$(".block-item-video-button").on(
			Events.click,
			main.controller.onClickImportVideoFromUrl
		);

		$(
			".blocks-item input,textarea,select, .form-area-wysiwyg, .tarif-input-label,.tarif-input-price"
		).off(Events.change, main.controller.onChangeBlockInputs);
		$(
			".blocks-item input,textarea,select, .form-area-wysiwyg, .tarif-input-label,.tarif-input-price"
		).on(Events.change, main.controller.onChangeBlockInputs);

		$("#form-item-playlist input,textarea,select, .form-area-wysiwyg").off(
			Events.change,
			main.controller.onChangeBlockInputs
		);
		$("#form-item-playlist input,textarea,select, .form-area-wysiwyg").on(
			Events.change,
			main.controller.onChangeBlockInputs
		);

		$(".blocks-item-bt-delete").off(
			Events.click,
			main.controller.onClickBlockDelete
		);
		$(".blocks-item-bt-delete").on(
			Events.click,
			main.controller.onClickBlockDelete
		);

		$(".blocks-dropdown-item").off(
			Events.click,
			main.controller.onClickBlocksDropdown
		);
		$(".blocks-dropdown-item").on(
			Events.click,
			main.controller.onClickBlocksDropdown
		);

		$(".block-btadd").off(Events.click, main.controller.onBtaddvideoClick);
		$(".block-btadd").on(Events.click, main.controller.onBtaddvideoClick);

		//Dates
		$(".dates-item-bt.close").off(
			Events.click,
			main.controller.onClickCloseDatesItem
		);
		$(".dates-item-bt.close").on(
			Events.click,
			main.controller.onClickCloseDatesItem
		);

		$(".dates-btadd").off(Events.click, main.controller.onClickDatesAdd);
		$(".dates-btadd").on(Events.click, main.controller.onClickDatesAdd);

		$(".dates-item-input").off(
			Events.change,
			main.controller.onChangeDates
		);
		$(".dates-item-input").on(Events.change, main.controller.onChangeDates);

		//Langs
		$(".form-item-langs-bt").off(
			Events.click,
			main.controller.prototype.onClickLeaveEdit
		);
		$(".form-item-langs-bt").on(
			Events.click,
			main.controller.prototype.onClickLeaveEdit
		);
	};

	controller.prototype.onClickImgDelete = function(e) {
		var parent = this.parentNode;
		var img = parent.querySelector("img");
		img.src = img.parentNode.getAttribute("data-default");
		var input = parent.querySelector(
			"input[name=" + parent.getAttribute("data-dbcolumn") + "]"
		);
		input.value = "";
	};

	controller.prototype.onTarifChange = function(e) {
		var select = document.querySelector("#form-select-tariftype-9");
		var value = select.value;

		var toSelect = document.getElementById("tarif-type-items-" + value);
		$(".tarif-type-items.selected").removeClass("selected");
		$(toSelect).addClass("selected");
	};

	controller.prototype.onRecrutementChange = function(e) {
		//console.log("onRecrutementChange");
		var $form = $("#profil-form-recrutement");
		var url = $form.attr("action");

		var serialize = $form.serialize();

		main.callPost(url, serialize, function(rs) {
			main.controller.prototype.hasChanged = false;
		});
	};

	controller.prototype.onActionContenuChange = function(e) {
		//console.log("onActionContenuChange");
		var hasConcert = document.querySelector(".select-has-concert");
		if (!hasConcert) return;

		var value = hasConcert.value;

		var $concert = $(".form-sep-container:nth-child(4)");
		var $concertValue = $concert.find(".input-value");
		var $selection = $concert.find(".highlight-selection");
		var $blocs = $(".blocks");

		if (value == 0) {
			//$concertValue.val(null);
			//$selection.removeClass("show");
			//$(".highlight-tosearch").addClass("show");
			$concert.find(".highlight").removeClass("warning");
			$concertValue.removeClass("validator-notempty");

			$concert.hide();
			$blocs.show();
		} else {
			$concertValue.addClass("validator-notempty");
			$concert.show();
			$blocs.hide();
		}
	};

	//Dates
	controller.prototype.onClickDatesAdd = function(e) {
		var index = document.querySelectorAll(".dates-item").length;

		var url = this.getAttribute("data-tpl");
		url += "/" + index;

		var items = document.querySelector(".dates-items");

		main.callGet(url, function(rs) {
			var div = document.createElement("div");
			div.innerHTML = rs;
			// console.info(rs);
			// console.log(items);
			items.appendChild(div.firstChild);

			main.controller.addListener();

			main.controller.setDates();
		});
	};

	controller.prototype.onClickCloseDatesItem = function(e) {
		var target = document.getElementById(this.getAttribute("data-target"));
		target.parentNode.removeChild(target);

		main.controller.setDates();
	};

	controller.prototype.onChangeDates = function(e) {
		main.controller.setDates();
	};

	controller.prototype.setDates = function(e) {
		var input = document.querySelector(".dates-item-value");
		var items = document.querySelectorAll(".dates-item");

		var i;
		var lg = items.length;
		var item;
		var values = [];
		var value;
		for (i = 0; i < lg; i++) {
			item = items[i];

			value = {};
			value.index = i;
			value.lieu = item.querySelector(".dates-item-lieu").value;
			value.date = item.querySelector(".dates-item-date").value;

			values.push(value);
		}
		input.value = btoa(encodeURI(JSON.stringify(values)));

		main.controller.prototype.setHasChanged(true);
	};

	//Dates
	controller.prototype.onDatesChange = function(e) {
		var dateItems = this.parentNode.parentNode.parentNode;
		var input = dateItems.querySelector(".dates-item-value");
		var dates = dateItems.querySelectorAll(".dates-item");

		var i;
		var lg = dates.length;
		var date;
		var values = [];
		var value;
		var setValue = function() {
			if ($(this).hasClass("form-select")) {
				value[this.getAttribute("name")] = this.options[
					this.selectedIndex
				].value;
			} else {
				value[this.getAttribute("name")] = this.value;
			}
		};
		for (i = 0; i < lg; i++) {
			date = dates[i];

			value = {};

			$(date.querySelectorAll(".dates-item-data")).each(setValue);

			date.classList.remove("checked");
			if (value.adresse_id && value.adresse_id != -1 && value.date) {
				values.push(value);
				date.classList.add("checked");
			} else {
				values.push(null);
			}
		}
		input.value = btoa(encodeURI(JSON.stringify(values)));
	};

	controller.prototype.onBlockVideoChange = function(e) {
		var bt = this.parentNode.querySelector(".block-item-video-button");
		$(bt).removeClass("disabled");

		main.controller.blocksHasChanged = true;
	};

	controller.prototype.addCredit = function(e) {
		var index = $("#credits").find(".credits-item").length;
		var url = main.controller.urlCredit + "/" + index;
		main.callGet(url, function(credit) {
			$(".credits-items").append(credit);

			main.controller.addListener();
			main.controller.prototype.setHasChanged(true);
		});
	};

	controller.prototype.savelist = function(e) {
		if (e) e.preventDefault();

		var url = main.controller.urlSave;
		var form = document.getElementById("form-list");
		var token = form.querySelector("input[name=_token]").value;

		//Get list id for save order
		var ids = [];
		$(".form-list-selected-item").each(function() {
			ids.push(this.getAttribute("data-id"));
		});

		if (ids.length < 5) {
			alert("Il faut au minimum 5 projet dans la liste");
			return false;
		}

		ids = ids.toString();
		//Show loading
		$(".actionbar-item").addClass("actionbar-item-disabled");
		$(".actionbar-state-item").removeClass("actionbar-state-show");
		$(".actionbar-state-loading").addClass("actionbar-state-show");

		var post = "_token=" + token + "&ids=" + ids;
		main.callPost(url, post, function(rs) {
			rs = JSON.parse(rs);

			$(".actionbar-item").removeClass("actionbar-item-disabled");
			$(".actionbar-state-loading").removeClass("actionbar-state-show");
		});

		return false;
	};

	controller.prototype.onDropSlider = function(target, response) {
		$target = $(target);
		//console.log(response);
		var rs = JSON.parse(response);
		if (rs.status) {
			$target.load(function() {
				$target.off();
			});

			//$target.html("");
			var $parent = $target.parent();
			var itemLength = $parent.find(
				".form-slider-item:not(.form-slider-item-btplus)"
			).length;
			var restrict = 10 - itemLength;
			var size =
				"/" +
				$target.attr("data-realw") +
				"/" +
				$target.attr("data-realh");
			var url = $target.attr("data-url");
			var filename = "";
			var i = 0;
			var lg = rs.filename.length;
			if (restrict) lg = lg > restrict ? restrict : lg;
			var img = null;
			var onCallGet = function(rs) {
				$target.before(rs);
				main.controller.addListener();
				main.controller.setSliderValue($target.parent()[0]);
			};
			for (i; i < lg; i++) {
				filename = rs.filename[i];

				main.callGet(
					main.controller.urlSlider +
						"/" +
						$parent.parent().attr("data-index") +
						"-" +
						(itemLength + i) +
						"/" +
						filename,
					onCallGet
				);
			}
			main.controller.prototype.setHasChanged(true);
			main.controller.blocksHasChanged = true;
		} else {
			alert(rs.errors);
		}
	};

	controller.prototype.onDelete = function(t) {
		if (window.uri == "project") {
			var target = t.parentNode.parentNode;
			target.parentNode.removeChild(target);

			$(".actionbar-state-changed").addClass("actionbar-state-show");
			main.controller.savelist();
		}
	};

	controller.prototype.onClickSelectorBtremove = function(e) {
		if ($(".form-list-selected-item").length < 5) {
			alert("Il faut au minimum 5 projet dans la liste");
			return false;
		}

		var target = this.parentNode.parentNode;
		target.parentNode.removeChild(target);

		$target = $(target);
		$target.removeClass("form-list-selected-item");
		$target.addClass("form-list-selector-item");

		$target.find(".form-list-selector-item-swapzone").hide();
		$target.find(".bt-activation").removeClass("isactive");
		$target
			.find(".form-list-selector-item-bts.left")
			.css("display", "none");
		$("#form-list-selector-toselect")
			.find(".form-list-selector-items")
			.append(target);

		$(".bt-activation.isactive").off(
			Events.click,
			main.controller.onClickSelectorBtremove
		);
		$(".bt-activation.isactive").on(
			Events.click,
			main.controller.onClickSelectorBtremove
		);

		$(".bt-activation:not(.isactive)").off(
			Events.click,
			main.controller.onClickSelectorBtadd
		);
		$(".bt-activation:not(.isactive)").on(
			Events.click,
			main.controller.onClickSelectorBtadd
		);

		$(".actionbar-state-changed").addClass("actionbar-state-show");
		main.controller.savelist();
	};

	controller.prototype.onClickSelectorBtadd = function(e) {
		var count = $(".form-list-selected-item").length;
		if (count >= 15) {
			alert(
				"La liste contient le maximum d'élément. Pour en ajouter un, il faut en enlever un."
			);
			return;
		}

		$(".actionbar-state-changed").addClass("actionbar-state-show");

		//$(".form-list-selector-column-label span").html(count + 1);
		var target = this.parentNode.parentNode;

		$(target).addClass("selected");

		var url = main.controller.urlSelector;
		main.callPost(
			url,
			"id=" +
				target.getAttribute("data-id") +
				"&title=" +
				target.getAttribute("data-title"),
			function(item) {
				$("#form-list-selector-selected")
					.find(".form-list-selector-items")
					.append(item);
				main.controller.savelist();
				main.controller.addListener();
			}
		);
	};

	controller.prototype.onClickBlocksDropdown = function() {
		var type = this.getAttribute("data-type");
		main.controller.addBlock(type);
		main.controller.blocksHasChanged = true;
	};

	controller.prototype.onDrop = function() {
		//target, response
		main.controller.blocksHasChanged = true;
	};

	controller.prototype.onBtaddvideoClick = function() {
		var self = main.controller;
		var index = $(".blocks").find(".blocks-item").length;
		var url = self.urlBlock + "/6/" + index + "/1";
		main.callGet(url, function(block) {
			$(".blocks")
				.find(".blocks-items")
				.append(block);

			main.controller.addListener();
			main.controller.blocksHasChanged = true;
		});
	};

	controller.prototype.addBlock = function(type) {
		//addBlock
		//var index = $("#profil-tab-content-0").find(".blocks-item").length;
		var index = $(".block").length;
		var url = this.urlBlock + "/" + type + "/" + index + "/1";
		main.callGet(url, function(block) {
			$(".blocks-items").append(block);

			main.controller.addListener();

			main.controller.prototype.setHasChanged(true);

			type = parseInt(type);
			if (type === 7 || type === 12 || type === 13) {
				main.controller.prototype.removeWysiwyg();
				main.controller.prototype.addWysiwyg();
			}

			main.controller.setBlocks();
		});
	};

	controller.prototype.onClickSliderItemDelete = function(e) {
		if (
			!confirm(
				"Cette action supprimera l'élément. Ok pour valider l'action."
			)
		) {
			e.preventDefault();
			return false;
		}
		var target = document.getElementById(this.getAttribute("data-target"));
		var parent = target.parentNode;
		parent.removeChild(target);

		main.controller.setSliderValue(parent);
		main.controller.prototype.setHasChanged(true);

		main.controller.blocksHasChanged = true;
	};

	controller.prototype.setSliderValue = function(slider) {
		var $slider = $(slider);
		var values = [];
		$slider
			.find(".form-slider-item:not(.form-slider-item-btplus)")
			.each(function() {
				values.push(
					this.querySelector("img").getAttribute("data-value")
				);
			});
		values = values.join(",");
		$slider
			.parent()
			.find(".form-img-input")
			.attr("value", values);

		main.controller.blocksHasChanged = true;
	};

	controller.prototype.onClickBlockDelete = function(e) {
		if (
			!confirm(
				"Cette action supprimera l'élément. Ok pour valider l'action."
			)
		) {
			e.preventDefault();
			return false;
		}
		var target = document.getElementById(this.getAttribute("data-target"));
		target.parentNode.removeChild(target);

		main.controller.prototype.setHasChanged(true);
		main.controller.blocksHasChanged = true;
	};

	controller.prototype.onChangeBlockInputs = function() {
		this.setAttribute("data-value", this.value);
		main.controller.prototype.setHasChanged(true);
		main.controller.blocksHasChanged = true;
	};

	controller.prototype.onTbBlur = function(e) {
		var tb = e.target.parentNode.querySelector(".trumbowyg-editor");
		e.target.setAttribute("data-value", tb.innerHTML);
		e.target.value = tb.innerHTML;
		main.controller.prototype.setHasChanged(true);
		main.controller.blocksHasChanged = true;
	};

	controller.prototype.afterSwap = function($to, $from) {
		$to.find("input,textarea,select").each(function() {
			if (this.hasAttribute("data-value")) {
				this.value = this.getAttribute("data-value");
			}
		});

		$from.find("input,textarea,select").each(function() {
			if (this.hasAttribute("data-value")) {
				this.value = this.getAttribute("data-value");
			}
		});

		if ($to.hasClass("form-slider-item")) {
			main.controller.setSliderValue($to[0].parentNode);
		}

		if ($to.hasClass("playlist-item")) {
			$(".playlist-item-label:first-child").trigger("change");
			if (window.uri == "/playlist") {
				main.controller.savePlaylist();
			}
		}

		if ($to.hasClass("blocks-item")) {
			main.controller.blocksHasChanged = true;
		}

		if ($to.hasClass("dates-item")) {
			main.controller.setDates();
		}

		main.controller.addListener();
	};

	controller.prototype.onClickImportVideoFromUrl = function(e) {
		$(".block-item-video-button").off(
			Events.click,
			main.controller.onClickImportVideoFromUrl
		);

		var $parent = $(this)
			.parent()
			.parent();
		var $bt = $parent.find(".button");
		var $loading = $parent.find(".form-loading");
		var urlSave = $parent.attr("data-urlsave");

		var $block = $parent.parent();
		var $input = $parent.find(".form-input-videolink");
		//if (!$input) $input = $parent.find("input[name=youtube_id]");
		//console.info($input);
		var value = $input.val();
		$input.attr("value", value);

		var isYoutube =
			value.indexOf("youtube") != -1 || value.indexOf("youtu.be") != -1;
		var isVimeo = value.indexOf("vimeo") != -1;

		$bt.css("display", "none");
		$loading.css("display", "block");

		if (!isYoutube && !isVimeo) {
			$(".block-item-video-button").on(
				Events.click,
				main.controller.onClickImportVideoFromUrl
			);
			$bt.css("display", "block");
			$loading.css("display", "none");
			alert("Le lien ne semble pas être de youtube ou de viméo.");
			return;
		}

		var media_type = isYoutube ? 0 : 1;
		$block.find("input[name=media_type]").val(media_type);

		var id = isYoutube
			? window.getYoutubeId(value)
			: window.getVimeoId(value);
		$block.find("input[name=media]").val(id);

		if (id === "") {
			$(".block-item-video-button").on(
				Events.click,
				main.controller.onClickImportVideoFromUrl
			);
			$bt.css("display", "block");
			$loading.css("display", "none");
			alert("Le lien est incorrect.");
			return;
		}

		var urlImg = "";
		var $youtube = $block.find(".form-youtube");
		var $vimeo = $block.find(".form-vimeo");
		if (isYoutube) {
			$youtube.attr("src", window.getYoutubeSrc(id));
			$youtube.css("display", "block");
			$vimeo.css("display", "none");

			urlImg = window.getYoutubeImgUrl(id);
			main.controller.saveImgImportedFromVideo(
				urlImg,
				urlSave,
				$bt,
				$loading,
				$block,
				id
			);
		} else {
			$vimeo.attr("src", window.getVimeoSrc(id));
			$vimeo.css("display", "block");
			$youtube.css("display", "none");

			var url = window.getVimeoApiUrl(id);
			main.callGet(url, function(rs) {
				var resultat = JSON.parse(rs);
				if (resultat[0]) {
					resultat = resultat[0];
					urlImg = resultat.thumbnail_large;
					urlImg = urlImg.replace("640.webp", "1280.jpg");
					urlImg = urlImg.replace("640.jpg", "1280.jpg");
					urlImg = urlImg.replace("640.jpeg", "1280.jpeg");

					main.controller.saveImgImportedFromVideo(
						urlImg,
						urlSave,
						$bt,
						$loading,
						$block,
						id
					);
				}
			});
		}

		main.controller.prototype.setHasChanged(true);
	};

	controller.prototype.saveImgImportedFromVideo = function(
		urlImg,
		urlSave,
		$bt,
		$loading,
		$block,
		id
	) {
		if (!urlSave) {
			$bt.css("display", "block");
			$loading.css("display", "none");
			$bt.parent().addClass("disabled");
			return false;
		}

		main.callGet(urlSave + "/" + btoa(urlImg) + "/article-" + id, function(
			name
		) {
			var $target = $block.find(".form-img-content");
			$bt.css("display", "block");
			$loading.css("display", "none");
			if (!$target[0]) return;
			$target.load(function() {
				$target.off();
			});
			$target.html("");
			var restrict = $target.attr("data-restrict")
				? parseInt($target.attr("data-restrict"))
				: null;
			var size =
				"/" +
				$target.attr("data-realw") +
				"/" +
				$target.attr("data-realh");
			var value = name;
			var url = $target.attr("data-url") + "/" + value + size;
			var img = document.createElement("img");
			img.src = url;
			img.className = "form-img form-img-imgs";
			img.style.zIndex = 0;
			img.style.width = $target.attr("data-width") + "px";
			//img.style.height = $target.attr("data-height") + "px";
			$target.append(img);
			$target
				.parent()
				.find(".form-img-input")
				.attr("value", value);

			main.controller.prototype.addDroppable();
			$(".block-item-video-button").on(
				Events.click,
				this.onClickImportVideoFromUrl
			);

			$bt.parent().addClass("disabled");
		});

		$(".block-item-video-button").on(
			Events.click,
			this.onClickImportVideoFromUrl
		);
	};

	controller.prototype.setImg = function(e) {
		var imgs = $(".set-img");
		if (imgs.length === 0) return;
		var i = 0;
		var lg = 5;
		var img = null;
		var setImg = function() {
			main.controller.countSetImg = 0;
			main.controller.setImg();
		};
		var onError = function() {
			img.setAttribute("src", img.getAttribute("data-src"));
		};
		var onload = function() {
			$(this).removeClass("set-img");
			main.controller.countSetImg++;
			if (main.controller.countSetImg >= 5) {
				setTimeout(setImg, 300);
			}
		};
		for (i = 0; i < lg; i++) {
			img = imgs[i];
			img.onload = onload;
			img.onerror = onError;
			img.setAttribute("src", img.getAttribute("data-src"));
		}
	};

	controller.prototype.onClickSelectorItem = function(e) {
		if (this.className.indexOf("checked") != -1) return;

		$(this).addClass("checked");

		var url = main.controller.urlSelector;
		main.callPost(
			url,
			"id=" +
				this.getAttribute("data-id") +
				"&title=" +
				this.getAttribute("data-title"),
			function(item) {
				$("#form-list-selector-selected")
					.find(".form-list-selector-items")
					.append(item);
				//main.controller.addListener();
			}
		);
	};

	controller.prototype.setSelectorValue = function() {
		var input = document.getElementById("projects-selected");
		var ids = [];
		var id = 0;
		$("#form-list-selector-selected")
			.find(".form-list-selector-item")
			.each(function() {
				id = this.getAttribute("data-id");
				ids.push(id);
			});

		input.value = ids.toString();
	};

	controller.prototype.onSwapSelected = function($to, $from, sens) {
		if (sens === -1) {
			$from.insertAfter($to);
		} else {
			$from.insertBefore($to);
		}

		main.controller.setSelectorValue();
		$(".form-list-selector-icon-swap").draggable(
			main.controller.onSwapSelected
		);
	};

	controller.prototype.onChangeMedia = function(e) {
		var value = parseInt(this.value);
		var target = this.parentNode.parentNode;
		var $vimeo = $(target).find(".form-item-vimeo");
		var $youtube = $(target).find(".form-item-youtube");
		$vimeo.hide();
		$youtube.hide();
		if (value === 0) {
			$youtube.show();
		} else {
			$vimeo.show();
		}
	};

	controller.prototype.customCheck = function() {
		var num_error = 0;
		var check = true;
		$(".validator-mediatype").each(function() {
			var value = parseInt(this.value);
			var target = this.parentNode.parentNode;
			var $vimeo = $(target).find(".form-input-vimeo");
			var $youtube = $(target).find(".form-input-youtube");

			$vimeo.parent().removeClass("warning");
			$youtube.parent().removeClass("warning");

			$input = value === 0 ? $youtube : $vimeo;

			check = !check ? check : $input.val().length > 0;
			if (!check) {
				$input.parent().addClass("warning");
				num_error++;
			}
		});

		return num_error;
	};

	controller.prototype.getBlocksToSend = function() {
		var blocks = [];
		var tab, i, j, lg, lj, serialized;

		var blockElements = document.querySelectorAll(".block");
		for (k = 0; k < blockElements.length; k++) {
			var blockElement = blockElements[k];
			var items = blockElement.querySelectorAll(".block-item");
			lg = items.length;

			var block = {
				type: blockElement.getAttribute("data-type"),
				items: []
			};

			for (i = 0; i < lg; i++) {
				var item = items[i];
				tab = {};
				serialized = $(item).serializeArray();
				serialized.push({
					name: "type",
					value: item.querySelector("select[name=type]").value
				});
				lj = serialized.length;
				for (j = 0; j < lj; j++) {
					tab[serialized[j].name] = serialized[j].value;
				}
				block.items.push(tab);
			}
			blocks.push(block);
		}
		return blocks;
	};

	controller.prototype.onSaveForm = function() {
		var blocks;

		var rs = {
			check: true,
			add: ""
		};

		//console.log(window.uri);
		if (window.uri.indexOf("/clients") != -1) {
			blocks = this.getBlocksToSend();
			//console.log(blocks);
			blocks = btoa(encodeURI(JSON.stringify(blocks)));
			rs.add =
				"&blocks=" +
				blocks +
				"&blocksHasChanged=" +
				this.blocksHasChanged;
		}

		this.blocksHasChanged = false;

		return rs;
	};

	controller.prototype.dispose = function() {
		this.prototype.dispose.call(this);
	};
})(AKARUCMS);
