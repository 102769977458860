(function(ns) {
	ns.UserController = function() {
		ns.AbstractController.call(this); //super constructor
	};
	var controller = ns.UserController;
	controller.prototype = Object.create(ns.AbstractController); //extends
	controller.prototype.constructor = controller; //set constructor
	controller.prototype.resize = function(w, h) {
		this.prototype.resize.call(this, w, h);
	};

	controller.prototype.init = function() {
		this.prototype.init.call(this);

		this.prototype.addDroppable.call(this);
	};

	controller.prototype.dispose = function() {
		this.prototype.dispose.call(this);
	};
})(AKARUCMS);
